import React, { ReactNode } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppSelector } from 'hooks/useStore';
import { selectLoggedIn } from 'store/modules/auth';

interface AuthWrappersProps extends RouteProps {
  children: ReactNode;
  redirectTo?: string;
}

export const PrivateRoute = ({
  children,
  redirectTo = '/signin',
  ...otherProps
}: AuthWrappersProps) => {
  const isLoggedIn = useAppSelector(selectLoggedIn);

  if (isLoggedIn) {
    return <Route {...otherProps}>{children}</Route>;
  }

  return (
    <Redirect
      to={{
        pathname: redirectTo,
        state: { from: otherProps.location?.pathname },
      }}
    />
  );
};

export const PublicRoute = ({
  children,
  redirectTo = '/app/analytics',
  ...otherProps
}: AuthWrappersProps) => {
  const isLoggedIn = useAppSelector(selectLoggedIn);

  if (!isLoggedIn) {
    return <Route {...otherProps}>{children}</Route>;
  }

  return <Redirect to={redirectTo} />;
};
