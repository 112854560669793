import moment, { Moment } from 'moment-timezone';

import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const INFLUX_START_DATE = '2018-02-10 00:00:00';

export const DATE_RANGE_OPTIONS = {
  // last1h: {
  //   text: 'Last 1 Hour',
  //   value: 'last1h',
  //   dateRange: {
  //     start: () => moment().tz('America/Los_Angeles').subtract(1, 'h'),
  //     end: () => moment().tz('America/Los_Angeles'),
  //   },
  //   dateRangeUTC: {
  //     start: () => moment().tz('UTC').subtract(1, 'h'),
  //     end: () => moment().tz('UTC'),
  //   },
  // },
  // last3h: {
  //   text: 'Last 3 Hours',
  //   value: 'last3h',
  //   dateRange: {
  //     start: () => moment().tz('America/Los_Angeles').subtract(3, 'h'),
  //     end: () => moment().tz('America/Los_Angeles'),
  //   },
  //   dateRangeUTC: {
  //     start: () => moment().tz('UTC').subtract(3, 'h'),
  //     end: () => moment().tz('UTC'),
  //   },
  // },
  // last6h: {
  //   text: 'Last 6 Hours',
  //   value: 'last6h',
  //   dateRange: {
  //     start: () => moment().tz('America/Los_Angeles').subtract(6, 'h'),
  //     end: () => moment().tz('America/Los_Angeles'),
  //   },
  //   dateRangeUTC: {
  //     start: () => moment().tz('UTC').subtract(6, 'h'),
  //     end: () => moment().tz('UTC'),
  //   },
  // },
  // last12h: {
  //   text: 'Last 12 Hours',
  //   value: 'last12h',
  //   dateRange: {
  //     start: () => moment().tz('America/Los_Angeles').subtract(12, 'h'),
  //     end: () => moment().tz('America/Los_Angeles'),
  //   },
  //   dateRangeUTC: {
  //     start: () => moment().tz('UTC').subtract(12, 'h'),
  //     end: () => moment().tz('UTC'),
  //   },
  // },
  // last24h: {
  //   text: 'Last 24 Hours',
  //   value: 'last24h',
  //   dateRange: {
  //     start: () => moment().tz('America/Los_Angeles').subtract(24, 'h'),
  //     end: () => moment().tz('America/Los_Angeles'),
  //   },
  //   dateRangeUTC: {
  //     start: () => moment().tz('UTC').subtract(24, 'h'),
  //     end: () => moment().tz('UTC'),
  //   },
  // },
  today: {
    text: 'Today',
    value: 'today',
    dateRange: {
      start: () => moment().tz('America/Los_Angeles').startOf('day'),
      end: () => moment().tz('America/Los_Angeles'),
    },
    dateRangeUTC: {
      start: () => moment().tz('UTC').startOf('day'),
      end: () => moment().tz('UTC'),
    },
  },
  yesterday: {
    text: 'Yesterday',
    value: 'yesterday',
    dateRange: {
      start: () => moment().tz('America/Los_Angeles').subtract(1, 'd').startOf('day'),
      end: () => moment().tz('America/Los_Angeles').subtract(1, 'd').endOf('day'),
    },
    dateRangeUTC: {
      start: () => moment().tz('UTC').subtract(1, 'd').startOf('day'),
      end: () => moment().tz('UTC').subtract(1, 'd').endOf('day'),
    },
  },
  last7d: {
    text: 'Last 7 Days',
    value: 'last7d',
    dateRange: {
      start: () =>
        moment().tz('America/Los_Angeles').endOf('day').subtract(6, 'd').startOf('day'),
      end: () => moment().tz('America/Los_Angeles'),
    },
    dateRangeUTC: {
      start: () => moment().tz('UTC').endOf('day').subtract(6, 'd').startOf('day'),
      end: () => moment().tz('UTC'),
    },
  },
  prev7d: {
    text: 'Prev 7 Days',
    value: 'prev7d',
    dateRange: {
      start: () =>
        moment()
          .tz('America/Los_Angeles')
          .subtract(1, 'd')
          .endOf('day')
          .subtract(6, 'd')
          .startOf('day'),
      end: () => moment().tz('America/Los_Angeles').subtract(1, 'd').endOf('day'),
    },
    dateRangeUTC: {
      start: () =>
        moment().tz('UTC').subtract(1, 'd').endOf('day').subtract(6, 'd').startOf('day'),
      end: () => moment().tz('UTC').subtract(1, 'd').endOf('day'),
    },
  },
  last30d: {
    text: 'Last 30 Days',
    value: 'last30d',
    dateRange: {
      start: () =>
        moment().tz('America/Los_Angeles').endOf('day').subtract(29, 'd').startOf('day'),
      end: () => moment().tz('America/Los_Angeles'),
    },
    dateRangeUTC: {
      start: () => moment().tz('UTC').endOf('day').subtract(29, 'd').startOf('day'),
      end: () => moment().tz('UTC'),
    },
  },

  monthToYesterday: {
    text: 'Month to Yesterday',
    value: 'monthToYesterday',
    dateRange: {
      start: () =>
        moment().tz('America/Los_Angeles').subtract(1, 'd').endOf('day').startOf('month'),
      end: () => moment().tz('America/Los_Angeles').subtract(1, 'd').endOf('day'),
    },
    dateRangeUTC: {
      start: () => moment().tz('UTC').subtract(1, 'd').endOf('day').startOf('month'),
      end: () => moment().tz('UTC').subtract(1, 'd').endOf('day'),
    },
  },
  yearToYesterday: {
    text: 'Year to Yesterday',
    value: 'yearToYesterday',
    dateRange: {
      start: () =>
        moment().tz('America/Los_Angeles').subtract(1, 'd').endOf('day').startOf('year'),
      end: () => moment().tz('America/Los_Angeles').subtract(1, 'd').endOf('day'),
    },
    dateRangeUTC: {
      start: () => moment().tz('UTC').subtract(1, 'd').endOf('day').startOf('year'),
      end: () => moment().tz('UTC').subtract(1, 'd').endOf('day'),
    },
  },
  custom: {
    text: 'Custom',
    value: 'custom',
    dateRange: {
      start: (d: Moment) =>
        moment(d).endOf('day').tz('America/Los_Angeles').startOf('day'),
      end: (d: Moment) => {
        const isToday = moment(d).isSame(moment(), 'day');
        if (isToday) {
          return moment().tz('America/Los_Angeles');
        }
        return moment(d).endOf('day').tz('America/Los_Angeles').endOf('day');
      },
    },
    dateRangeUTC: {
      start: (d: Moment) => moment(d).endOf('day').tz('UTC').startOf('day'),
      end: (d: Moment) => {
        const isToday = moment(d).isSame(moment(), 'day');
        if (isToday) {
          return moment().tz('UTC');
        }
        return moment(d).endOf('day').tz('UTC').endOf('day');
      },
    },
  },
};

export const DATE_RANGE_OPTIONS_STATS = {
  last1h: {
    text: 'Last 1 Hour',
    value: 'last1h',
    dateRange: {
      start: () => dayjs().utc().subtract(1, 'h'),
      end: () => dayjs().utc(),
    },
  },
  last3h: {
    text: 'Last 3 Hours',
    value: 'last3h',
    dateRange: {
      start: () => dayjs().utc().subtract(3, 'h'),
      end: () => dayjs().utc(),
    },
  },
  last6h: {
    text: 'Last 6 Hours',
    value: 'last6h',
    dateRange: {
      start: () => dayjs().utc().subtract(6, 'h'),
      end: () => dayjs().utc(),
    },
  },
  last12h: {
    text: 'Last 12 Hours',
    value: 'last12h',
    dateRange: {
      start: () => dayjs().utc().subtract(12, 'h'),
      end: () => dayjs().utc(),
    },
  },
  last24h: {
    text: 'Last 24 Hours',
    value: 'last24h',
    dateRange: {
      start: () => dayjs().utc().subtract(24, 'h'),
      end: () => dayjs().utc(),
    },
  },

  last7d: {
    text: 'Last 7 Days',
    value: 'last7d',
    dateRange: {
      start: () => dayjs().utc().endOf('day').subtract(6, 'd').startOf('day'),
      end: () => dayjs().utc(),
    },
  },
  prev7d: {
    text: 'Prev 7 Days',
    value: 'prev7d',
    dateRange: {
      start: () =>
        dayjs().utc().subtract(1, 'd').endOf('day').subtract(6, 'd').startOf('day'),
      end: () => dayjs().utc().subtract(1, 'd').endOf('day'),
    },
  },
  last30d: {
    text: 'Last 30 Days',
    value: 'last30d',
    dateRange: {
      start: () => dayjs().utc().endOf('day').subtract(29, 'd').startOf('day'),
      end: () => dayjs().utc(),
    },
  },
  today: {
    text: 'Today',
    value: 'today',
    dateRange: {
      start: () => dayjs().utc().startOf('day'),
      end: () => dayjs().utc(),
    },
  },
  yesterday: {
    text: 'Yesterday',
    value: 'yesterday',
    dateRange: {
      start: () => dayjs().utc().subtract(1, 'd').startOf('day'),
      end: () => dayjs().utc().subtract(1, 'd').endOf('day'),
    },
  },
  monthToYesterday: {
    text: 'Month to Yesterday',
    value: 'monthToYesterday',
    dateRange: {
      start: () => dayjs().utc().subtract(1, 'd').endOf('day').startOf('month'),
      end: () => dayjs().utc().subtract(1, 'd').endOf('day'),
    },
  },
  yearToYesterday: {
    text: 'Year to Yesterday',
    value: 'yearToYesterday',
    dateRange: {
      start: () => dayjs().utc().subtract(1, 'd').endOf('day').startOf('year'),
      end: () => dayjs().utc().subtract(1, 'd').endOf('day'),
    },
  },
  custom: {
    text: 'Custom',
    value: 'custom',
    dateRange: {
      start: (d: Dayjs) => dayjs(d),
      end: (d: Dayjs) => dayjs(d),
    },
  },
  lastFullWeek: {
    text: 'Last Full Week',
    value: 'lastFullWeek',
    dateRange: {
      start: () => dayjs().utc().subtract(1, 'week').startOf('week').startOf('day'),
      end: () =>
        dayjs().utc().subtract(1, 'week').startOf('week').startOf('day').endOf('week'),
    },
  },
};

export const HITS_FIELD = 'hitsTotal';
export const TRAFFIC_FIELD = 'gigsTotal';

export const DRILLDOWN_PAGE_MAP = {
  'overview-old': 'Overview',
  realtime: 'Realtime Requests',
  'traffic-old': 'Traffic',
  'cache-ratio-old': 'Cache Hit Ratio',
  'countries-old': 'Countries',
  'pops-old': 'Pops',
  files: 'Files',
  'referrers-old': 'Referrers',
  '95th': '95th Percentile',
  'status-codes': 'Status Codes',
  'cache-ratio': 'Cache Ratio',
  origin: 'Origin',
  pops: 'Pops',
  countries: 'Countries',
  'object-storage': 'Object Storage',
  traffic: 'Traffic',
  referrers: 'Referrers',
  overview: 'Overview',
  'realtime-old': 'Realtime Requests',
};
