import React, { Suspense, lazy } from 'react';
import { PrivateRoute, PublicRoute } from 'utils/auth-wrappers';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import PageLeavePromptProvider from '../components/PageLeavePromptProvider';
import useConfirmationDialog from '../components/ConfirmationDialog/useConfirmationDialog';
import Spinner from 'components/Spinner';
import useCurrentUser from 'hooks/useCurrentUser';

const SignInPage = lazy(() => import('../containers/Pages/SignInPage'));
const SignInErrorPage = lazy(() => import('../containers/Pages/SignInErrorPage'));
const SignupPage = lazy(() => import('../containers/Pages/SignupPage'));
const UnauthorizedPage = lazy(() => import('../containers/Pages/UnauthorizedPage'));
const ForgotPassPage = lazy(() => import('../containers/Pages/ForgotPassPage'));
const EmailConfirmationPage = lazy(
  () => import('../containers/Pages/EmailConfirmationPage'),
);
const SignInFromPortalPage = lazy(
  () => import('../containers/Pages/SignInFromPortalPage'),
);
const AuthenticatedRoutes = lazy(() => import('./AuthenticatedRoutes'));

const GoogleAccountLinking = lazy(
  () => import('../containers/Pages/Oauth/GoogleAccountLinking'),
);
const GoogleAccountSignin = lazy(
  () => import('../containers/Pages/Oauth/GoogleAccountSignin'),
);

const App = () => {
  const { user } = useCurrentUser();

  const history = useHistory<{
    from: string;
  }>();

  const redirectUrl = !history.location.state?.from.includes('signin')
    ? history.location.state?.from
    : '';

  return (
    <div className="app-main">
      <Suspense fallback={<Spinner main />}>
        <Switch>
          <PublicRoute exact path="/unauthorized">
            <UnauthorizedPage />
          </PublicRoute>

          <PublicRoute
            exact
            path="/signin"
            redirectTo={
              redirectUrl ||
              (!user?.uiPermissions?.configuration ? '/app/accounts' : '/app/analytics')
            }
          >
            <SignInPage />
          </PublicRoute>

          <PublicRoute exact path="/signin/error">
            <SignInErrorPage />
          </PublicRoute>

          <PublicRoute exact path="/signup" redirectTo="/app/services">
            <SignupPage />
          </PublicRoute>

          <PublicRoute exact path="/signup/:campaignName" redirectTo="/app/services">
            <SignupPage />
          </PublicRoute>

          <PublicRoute exact path="/forgotpass">
            <ForgotPassPage />
          </PublicRoute>

          {/* <Route exact path="/portal-auth/:token" component={SignInFromPortalPage} /> */}
          <Route path="/portal-auth/:token/:path*" component={SignInFromPortalPage} />

          <PublicRoute exact path="/email-confirm">
            <EmailConfirmationPage />
          </PublicRoute>

          <PublicRoute path="/auth/google/callback">
            <GoogleAccountSignin />
          </PublicRoute>

          <PrivateRoute path="/auth/google/link/callback">
            <GoogleAccountLinking />
          </PrivateRoute>

          <PrivateRoute path="/">
            <AuthenticatedRoutes />
          </PrivateRoute>
        </Switch>
      </Suspense>
    </div>
  );
};

const Routes = () => {
  const { getConfirmation } = useConfirmationDialog();

  return (
    <BrowserRouter
      getUserConfirmation={async (message, callback) => {
        const confirmed = await getConfirmation({
          title: 'Attention!',
          message: message,
          confirmBtnText: 'Yes',
          cancelBtnText: 'No',
        });

        callback(confirmed);
      }}
    >
      <PageLeavePromptProvider>
        <App />
      </PageLeavePromptProvider>
    </BrowserRouter>
  );
};

export default Routes;
